import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import RTL from "./RTL"
import { SnackbarProvider } from 'notistack';
ReactDOM.render(

		<SnackbarProvider maxSnack={3} autoHideDuration={2000}>
			<RTL><App /></RTL>
		</SnackbarProvider>,
  document.getElementById('root')
);