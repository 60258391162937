import React from 'react';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const theme = createMuiTheme({
	palette: {
		primary: {
		light: '#757ce8',
		main: '#3f50b5',
		dark: '#002884',
		contrastText: '#fff',
		},
	},
	direction: 'rtl',
});

export default function RTL(props) {
  return (
	<ThemeProvider theme={theme}>
		<StylesProvider jss={jss}>
			{props.children}
		</StylesProvider>
	</ThemeProvider>
  );
}