import React, {useState} from 'react';
import ReactDOM from "react-dom"

import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
	root: {
    flexGrow: 1,
    marginTop: theme.spacing(5),
  },
	
	payDone: {
    marginTop: theme.spacing(12),
  },
	
}));

const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });

export default function End(props) {
  const classes = useStyles();
	const {fee, paymentApproved} = props;
	const [payDone, setPayDone] = useState(false);

  const createOrder = (data, actions) =>{
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: 700+fee,
						//value: 1,
          },
        },
      ],
    });
  };

  const onApprove = (data, actions) => {
		return actions.order.capture()
		.then(details => {
			paymentApproved(data);
			setPayDone(true);
    });
  };
	
  return (
	<div className={classes.root}>
		{!payDone?
	  <React.Fragment>
			<Typography variant="h6"><b>הטופס נשלח בהצלחה!</b></Typography>
			<Box m={3} />
			<Typography color="primary">יש לכם שאלות לפני המשך התהליך? אין בעיה!</Typography>
			<Box m={1} />
			<Typography>לייעוץ מקצועי ללא עלות או התחייבות, אתם מוזמנים ליצור קשר עם אחד מעוה"ד המנוסים שלנו, בטלפון: <strong>077-3142421</strong>.</Typography>
			<Box m={4} />
			<Typography>או להמשיך לביצוע תשלום ותחילת טיפול בהגשת התביעה.</Typography>
			<Box m={1} />
			<Typography><strong>עלות הגשת התביעה: {700+fee} ש"ח</strong></Typography>
			<Typography>(700 ש"ח דמי שירות + {fee} ש"ח עמלת בית משפט)</Typography>
			<Box m={5} />
			<PayPalButton
				createOrder={(data, actions) => createOrder(data, actions)}
				onApprove={(data, actions) => onApprove(data, actions)}
			/>
		</React.Fragment>
		:
		<React.Fragment>
			<Typography className={classes.payDone} variant="h6"><b>התשלום בוצע בהצלחה!</b></Typography>
			<Box m={2} />
			<Typography>חשבונית דיגיטלית תישלח לכתובת האלקטרונית שלך.</Typography>
			<Box m={0.5} />
			<Typography>במידה ותרצה שנשלח לך חשבונית מודפסת, אנא שלח מייל לכתובת: <b>office@din-online.co.il</b>.</Typography>
			<Box m={4} />
			<Typography>תהליך הטיפול בהכנת התביעה מתחיל באופן מיידי, אנו ניצור קשר אם ידרשו פרטים נוספים.</Typography>
			
			
		</React.Fragment>
		}
	</div>
  );
}