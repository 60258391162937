import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import Pros from './Pros';
import Case from './Case';
import Sue from './Sue';
import Files from './Files';
import End from './End';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
		maxWidth: "500px",
		margin: "auto",
		marginTop: theme.spacing(8),
		padding: theme.spacing(0,2),
  },

	backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },

	logo: {
		textAlign: "center",
  },
	
	cardInfo: {
		minHeight: 468,
	},
	
	formInfo: {
		minHeight: 400,
	},

	title: {
    width: '100%',
    margin: theme.spacing(3.5, 0, 2, 0),
  },

	nav: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end",
		marginTop: "15px",
	},
	
	navButton: {
		width: "65px",
	},
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center" dir="rtl">
			{'© כל הזכויות שמורות ל'}
      <Link color="inherit" href="https://www.din-online.co.il">
				{'דין-אונליין - שירותים משפטיים מקוונים'}
      </Link>
			{'.'}      
    </Typography>
  );
}

export default function App() {
  const classes = useStyles();
	const [info, setInfo] = useState({id: Math.random().toString(36).substring(2,7), pros: [], resp: []});
	const [valid, setValid] = useState({pros: [], resp: []});
	const [check, setCheck] = useState(false);
	const [mode, setMode] = useState(0);
	const [uploadMode, setUploadMode] = useState(false);
	const [btnTitle, setBtnTitle] = useState("הבא");
	const titles = ["פרטי התובע/ים", "פרטי הנתבע/ים", "פרטי הארוע", "פרטי התביעה", "העלאת קבצים"];
	const [tabDisplayed, setTabDisplayed] = useState([0, 0]);
	
  useEffect(() => {
		var arr1 = Array.from(Array(2).keys())
		arr1.forEach(number => {
			setInfo(prev=>({...prev, pros: [...prev.pros, {enabled: number===0, id: number, name: "", iid: "", address: "", phone: "", email: ""}]}));
			setValid(prev=>({...prev, pros: [...prev.pros, {name: number!==0, iid: number!==0, phone: number!==0}]}));
		});
		
		var arr2 = Array.from(Array(3).keys())
		arr2.forEach(number => {
			setInfo(prev=>({...prev, resp: [...prev.resp, {enabled: number===0, id: number, name: "", iid: "", address: "", phone: "", email: ""}]}));
			setValid(prev=>({...prev, resp: [...prev.resp, {name: number!==0, phone: number!==0}]}));
		});
		setInfo(prev=>({...prev, case: {place: "", caseinfotype: 0, caseinfo: "", casefile: undefined, casephone: "", files:""}}));
		setInfo(prev=>({...prev, sue: {suetype: 1, ammount: "", fee: 50, expense: 0, court: 29}}));
		setInfo(prev=>({...prev, files: []}));
		setValid(prev=>({...prev, case: {place:false, info:false}, sue: {ammount: false}}));
  }, []);
	
	const btnClicked = (next) => {
		if (next) {
			if (mode<4) setMode(mode+1);
			if (mode===3) setBtnTitle("סיום");
			else if (mode===4) {
				setCheck(true);
				setBtnTitle("הבא");
				var [modefix, tabfix] = getErrorModes();
				if (modefix === -1) {
					//if (true) {
					uploadInfo();
				} else {
					setMode(modefix);
					if (tabfix !== -1) {
						updateTabDisplayed(modefix, tabfix);
					}
				}

			}
		}
		else if (!next) {
			if (mode===4) setBtnTitle("הבא");
			setMode(mode-1);
		}
	};
	
	const paymentApproved = (paymentoInfo) => {
		var obj = {...info, payment: paymentoInfo};
		uploadInfo(obj);
	}
	
	const uploadInfo = (obj=undefined) => {
		const fd = new FormData();
		var msgInfo = info;
		if (obj!==undefined) {
			msgInfo = obj;
		} else {
			if (info.case.casefile) fd.append('casefile', info.case.casefile.obj);
			info.files.forEach(item => {
				fd.append(item.name, item.obj);
			});
			if (info.case.casefile || info.files.length>0) setUploadMode(true);
		}
		fd.append('info', JSON.stringify(msgInfo));
		axios.post('https://form.din-online.co.il/srv/upload.php', fd)
			.then(res => {
				setUploadMode(false);
				setMode(5);
			});
	}
	
	const getErrorModes = () => {
		var [found, tabfix] = [false, 0];
		
		valid.pros.forEach(item => {
			if (!found) {
				if (!item.name || !item.iid || !item.phone) found = true;
				else tabfix += 1;
			}
		});
		if (found) return [0, tabfix];

		[found, tabfix] = [false, 0];
		valid.resp.forEach(item => {
			if (!found) {
				if (!item.name || !item.phone) found = true;
				else tabfix += 1;
			}
		});
		if (found) return [1, tabfix];
		
		if (!valid.case.place || !valid.case.info) return [2, -1];
		if (!valid.sue.ammount ) return [3, -1];
		return [-1, -1];
	}
	
	const updateObject = (group, newVal) => {
		let list = [...info[group]];
		list[newVal.id] = newVal;
		setInfo({...info, [group]: list});

		list = [...valid[group]];
		list[newVal.id] = {name: !newVal.enabled||newVal.name, iid: !newVal.enabled||(newVal.iid&&newVal.iid.length===9), phone: !newVal.enabled||newVal.phone};
		setValid({...valid, [group]: list});
	};
	
	const updateField = (group, field, newVal) => {
		setInfo(prev=>({...prev, [group]: {...prev[group], [field]: newVal}}));
		if (valid[group].hasOwnProperty(field)) {
			setValid(prev=>({...prev, [group]: {...prev[group], [field]: newVal!==""}}));
		}
		if (group==="case") {
			var infoValid = true;
			var caseinfotype = field==="caseinfotype"?newVal:info.case.caseinfotype;
			var caseinfo = field==="caseinfo"?newVal:info.case.caseinfo;
			var casefile = field==="casefile"?newVal:info.case.casefile;
			if ((caseinfotype===0&&caseinfo==="") || (caseinfotype===1&&casefile===undefined)) infoValid=false;
			if (infoValid!==valid.case.info) setValid({...valid, case: {...valid.case, info: infoValid}});
		}
		if (group==="sue"&&field==="ammount") {
			var ammount = parseInt(newVal);
			var ammountValid = false;
			var fee = 50;
			if (ammount>0 && ammount<=34600) {
				ammountValid=true;
				if (ammount>5000) fee = Math.ceil(ammount/500)*5;
			}
			setInfo(prev=>({...prev, sue: {...prev.sue, fee: fee}}));
			setValid({...valid, sue: {...valid.sue, ammount: ammountValid}});
		}
	};			
	
	const updateFiles = (add, val) => {
		if (val===undefined) return;
		if (add) {
			if (info.files.length<4) setInfo(prev=>({...prev, files: [...prev.files, {name: val.name, obj: val}]}));
		}
		else setInfo(prev=>({...prev, files: prev.files.filter(item => item !== val)}));
	}
	
	const updateTabDisplayed = (index, val) => {
		let newArr = [...tabDisplayed];
    newArr[index] = val;
    setTabDisplayed(newArr);
	}
	
  return (
    <div className={classes.root} dir="rtl">
      <CssBaseline />
			<Backdrop className={classes.backdrop} open={uploadMode}><CircularProgress color="inherit" /></Backdrop>
			<Typography className={classes.logo}><img src="logo.png" alt="" /></Typography>
			<div className={classes.cardInfo}>
				{(mode<=4)?
				<React.Fragment>
					<div className={classes.formInfo}>
						<Typography className={classes.title} variant="h6">{titles[mode]}</Typography>
						{mode===0?
						<Pros type={0} check={check} valid={valid.pros} displayed={tabDisplayed[0]} info={info.pros} infoChanged={val=>updateObject("pros", val)} tabChanged={val=>updateTabDisplayed(0, val)} />
						:mode===1?
						<Pros type={1} check={check} valid={valid.resp} displayed={tabDisplayed[1]} info={info.resp} infoChanged={val=>updateObject("resp", val)} tabChanged={val=>updateTabDisplayed(1, val)} />
						:mode===2?
						<Case check={check} valid={valid.case} info={info.case} infoChanged={(field, val)=>updateField("case", field, val)} />
						:mode===3?
						<Sue check={check} valid={valid.sue} info={info.sue} infoChanged={(field, val)=>updateField("sue", field, val)} />
						:
						<Files info={info.files} infoChanged={(add, val)=>updateFiles(add, val)} />
						}
					</div>
					<div className={classes.nav}>
						<Button className={classes.navButton} disabled={mode===0} variant="contained" color="primary" onClick={()=>btnClicked(false)}>חזור</Button>
						<Box m={1} />
						<Button className={classes.navButton} variant="contained" color="primary" onClick={()=>btnClicked(true)}>{btnTitle}</Button>
					</div>
				</React.Fragment>
				:
				<div>
				{info.sue && <End fee={info.sue.fee} paymentApproved={paymentoInfo=>paymentApproved(paymentoInfo)} ></End>}
				</div>
				}
			</div>
			<Box mt={0}><Copyright /></Box>
    </div>
  );
}