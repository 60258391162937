import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
	root: {
    flexGrow: 1,
    margin: theme.spacing(-1, 0, 0, 0),
  },
	
	deleteButton: {
		margin: theme.spacing(-1, 0, 0, -2),
	},
	
}));

export default function Files(props) {
  const classes = useStyles();
	const {info, infoChanged} = props

  return (
	<div className={classes.root}>
	  <React.Fragment>
			<Box m={3} />
			<Typography color="primary" className={classes.filesheader}>ניתן ורצוי לצרף מסמכים שמחזקים את טענותיך.</Typography>
			<Typography className={classes.filesheader}>לדוגמה: הסכמים, מכתבים, התכתבויות אחרות לרבות בדואר אלקטרוני ובמסרונים, קבלות וחשבוניות על תשלומים, תצלומים של נזק שנתבע פיצוי בעדו וכד'.</Typography>
			{info && info.length!==0 ? <Box m={1} /> : <Box m={6} />}
			{info && info.map(item => 
				<div key={item.name} className={classes.deleteButton}><IconButton onClick={(e)=>infoChanged(false, item)}><DeleteIcon /></IconButton> {item.name}</div>
			)}
			<Button variant="contained" component="label" className={classes.button} startIcon={<CloudUploadIcon />} >העלה קובץ<input type="file" onChange={(e)=>infoChanged(true, e.target.files[0])} hidden /></Button>
			<Box m={2} />
			<Typography className={classes.filesheader}><strong>אם אין בידכם מסמכים אלו כרגע אל דאגה! תוכלו לשלוח אותם בהמשך באימייל או בווטסאפ.</strong></Typography>
		</React.Fragment>

	</div>
  );
}